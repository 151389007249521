<template>
  <v-app class="grey_regular">
    <!-- Main Container -->
    <v-container
      class="py-0 fill-height top-menu-margin grey container-upcoming"
    >
      <v-row class="fill-height">
        <!--START:: Appointments Lists Section -->
        <v-col
          xl="3"
          lg="4"
          md="4"
          sm="12"
          xs="12"
          class="text-left pa-6 grey session-card-section"
        >
          <UpcomingSession
            v-if="isShow"
            :sessions="sessions"
            :default-selected-session="selectedSession"
            :card-title="$t('upc_session')"
            :no-session-text="$t('no_upcmng_session')"
            :selected-session.sync="selectedSession"
            :user-type="'host'"
            :is-disabled="isListDisabled"
          />
        </v-col>
        <!--END:: Appointments Lists Section -->

        <!--START:: Video Streaming Section -->
        <v-col v-if="isShow" xl="9" lg="8" md="8" sm="12" xs="12" class="pa-0">
          <VideoStreaming
            :session="selectedSession"
            :user-type="'host'"
            :is-list-disabled.sync="isListDisabled"
          />
        </v-col>
        <!--END:: Video Streaming Section -->
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

// graphql
import { GET_BOOKING } from '@/graphql/booking'

// Components
import UpcomingSession from '@/components/generic/SessionListingCard'
import VideoStreaming from '@/components/generic/VideoStreaming'

export default {
  components: {
    UpcomingSession,
    VideoStreaming,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      selectedSession: null,
      error: null,
      sessions: [],
      isListDisabled: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'jwt']),
  },

  async mounted() {
    await this.getSessionsLists()
  },

  methods: {
    async getSessionsLists() {
      try {
        const {
          data: { booking },
        } = await this.$apollo.query({
          query: GET_BOOKING,
          fetchPolicy: 'no-cache',
          variables: {
            bookinginput: {
              email: this.user.email,
              accesstoken: this.jwt,
            },
            pagination: {
              first: 0,
              skip: 0,
            },
            tzinfo: this.$defaultTZ,
          },
        })

        if (booking.success) {
          if (booking.bookedList && booking.bookedList.length) {
            this.sessions = []
            booking.bookedList.forEach((session, index) => {
              const date = session.startTime.split('T')[0]
              const sessionIndex = this.sessions.findIndex(
                info => info.date === date
              )
              session.sessionType.charge = this.$options.filters.toFixed(
                session.sessionType.charge,
                2
              )
              if (sessionIndex >= 0) {
                this.sessions[sessionIndex].sessions.push({
                  ...session,
                  index: index,
                })
              } else {
                this.sessions.push({
                  date: date,
                  sessions: [{ ...session, index: index }],
                })
              }
            })

            if (this.sessions.length) {
              this.selectedSession = this.sessions[0].sessions.find(
                session => session.index == 0
              )
            }
          }
        } else {
          this.error = this.$t(booking.error)
        }
        this.isShow = true
      } catch (e) {
        console.error(e) // eslint-disable-line
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1255px) and (min-width: 670px) {
  .container-upcoming {
    max-width: 100%;
  }
}

@media only screen and (max-width: 4000px) and (min-width: 1255px) {
  .container-upcoming {
    max-width: 90%;
  }
}

.session-card-section {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}
</style>
